import Methods from '@/api/methods'
export default {
  data() {
    return {
      memberRegisterFormExcel : '会員登録申請書.xlsx', // 会員登録申請フォーム Excel
      memberRegisterFormPdf   : '会員登録申請書.pdf', // 会員登録申請フォーム PDF
      activeRacingHorseExcel  : '現役競走馬販売申込書.xlsx', // 現役競走馬販売申込Excel
      activeRacingHorsePdf    : '現役競走馬販売申込書.pdf', // 現役競走馬販売申込PDF
      breedingMareExcel       : '繁殖牝馬販売申込書.xlsx', // 繁殖牝馬販売申込Excel
      breedingMarePdf         : '繁殖牝馬販売申込書.pdf', // 繁殖牝馬販売申込PDF
      businessProcessPdf      : 'サタデーオークション業務規程.pdf', // 受けたファイル名はこのファイル名に再設定する必要がある
      horseRequirementPdf     : '上場馬募集要項.pdf', // 上場馬募集要項
    }
  },
  methods : {
    downloadFile(key) {
      if (key) {
        window.open(`${process.env.VUE_APP_API_ENDPOINT.replace('api/', '')}files/${encodeURIComponent(key)}`, '_blank')
      }
    },
    downloadFileS3(key) {
      this.$store.state.loading = true
      Methods.apiExcute('private/download-file', this.$i18n.locale, {
        key
      }).then(data => {
        this.$store.state.loading = false
        console.log(`data: ${JSON.stringify(data)}`)
        window.open(data, '_blank')
      })
        .catch(error => {
          this.$store.state.loading = false
          Methods.parseHtmlResponseError(this.$router, error)
        })
    }
  },
}
